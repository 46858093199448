<template>
    <div class="mt-3 text-gray-700">
        <div v-if="patient.care_plans.length">
            <div v-for="carePlan in patient.care_plans" :key="carePlan.id">
                <div class="bg-white rounded-lg p-4 mt-2">
                    <div class="flex justify-between mb-2">
                        <div class="flex gap-2">
                            <p class="uppercase font-bold">{{ carePlan.type }} CARE PLAN</p>
                            <div>
                                <span v-if="carePlan.active"
                                    class="text-xs font-medium tracking-wider text-white px-2.5 py-0.5 bg-green-500 rounded">ACTIVE</span>
                            </div>
                        </div>
                        <button @click="editCarePlan(carePlan)"
                            class="text-gray-400 bg-transparent ring-1 ring-gray-400 hover:text-white hover:bg-gray-400 rounded-full text-sm font-medium text-center px-2 py-1">
                            Edit Care Plan
                        </button>
                    </div>
                    <div class="text-sm">
                        <div>
                            <div class="mb-2">
                                <p class="text-gray-500">Health Condition</p>
                                <p>{{ carePlan.health_condition || "-" }}</p>
                            </div>
                            <div class="mb-2">
                                <p class="text-gray-500">Notes</p>
                                <p>{{ carePlan.notes || "-" }}</p>
                            </div>
                            <div class="mb-2">
                                <p class="text-gray-500">Physician</p>
                                <p>{{ getProviderName(carePlan.physician_id) }}</p>
                            </div>
                            <div class="mb-2">
                                <p class="text-gray-500">Created by</p>
                                <p>{{ getProviderName(carePlan.creator_id) }}</p>
                            </div>
                            <div class="mb-2">
                                <p class="text-gray-500">Created at</p>
                                <p>{{ carePlan.created_at | datetime }}</p>
                            </div>
                            <div class="mb-2">
                                <p class="text-gray-500">Updated at</p>
                                <p>{{ carePlan.updated_at | datetime }}</p>
                            </div>
                            <div v-if="carePlan.type === 'apcm'">
                                <div class="mb-2">
                                    <p class="text-gray-500">APCM HCPCS Code</p>
                                    <p>{{ carePlan.apcm_hcpcs_code || "-" }}</p>
                                </div>
                            </div>
                        </div>
                        <div v-if="carePlan.type === 'rpm'">
                            <p class="text-gray-500">
                                Alerts are generated when vital readings fall outside acceptable
                                ranges.
                            </p>
                            <v-data-table  :headers="dtheaders" :items="carePlan.alerts" hide-default-footer disable-pagination />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="bg-white rounded-lg p-4">
                <p class="italic text-gray-500">This patient has no care plans yet.</p>
            </div>
        </div>
        <el-dialog :close-on-click-modal="false" :title="'Edit Care Plan'" :visible.sync="editCareplanVisible" width="50%" class="josefin_font"
            @close="() => (this.editCareplanVisible = false)">
            <div class="dialog__body">
                <div ref="patientEditCareplanRef">
                    <RpmDashboardErrors v-bind:errors="editCareplanErrors" v-bind:title="'Edit Care Plan Errors'">
                    </RpmDashboardErrors>

                    <RpmCarePlan :key="editCareplanSelected.id" v-if="editCareplanSelected.type === 'rpm'"
                        v-model="editCareplanSelected" :templates="rpmCarePlanTemplates" :patient="patient" />
                    <CcmCarePlan :key="editCareplanSelected.id" v-if="editCareplanSelected.type === 'ccm'"
                        v-model="editCareplanSelected" :templates="ccmCarePlanTemplates" :patient="patient" />
                    <ApcmCarePlan :key="editCareplanSelected.id" v-if="editCareplanSelected.type === 'apcm'"
                        v-model="editCareplanSelected" :templates="apcmCarePlanTemplates" :patient="patient" />
                </div>
            </div>
            <el-button type="primary" @click="updateCarePlan" :disabled="loading">Update Care Plan</el-button>
        </el-dialog>
    </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import RpmCarePlan from "@/components/RpmCarePlan.vue";
import CcmCarePlan from "@/components/CcmCarePlan.vue";
import ApcmCarePlan from "@/components/ApcmCarePlan.vue";
import RpmDashboardErrors from "@/components/RpmDashboardErrors.vue";

export default {
    components: { CcmCarePlan, RpmCarePlan, ApcmCarePlan, RpmDashboardErrors },
    props: {
        patient: Object,
        accessToken: null,
    },
    data() {
        return {
            loading: false,
            editCareplanVisible: false,
            editCareplanErrors: {},
            editCareplanSelected: {},
            rpmVitalAlerts: [
              { type: "weight", min: -5, max: 5, min_warning: -4, max_warning: 4, frequency: "daily" },
              { type: "pulse", min: 50, max: 100, min_warning: 55, max_warning: 95, frequency: "daily" },
              { type: "systolic", min: 95, max: 160, min_warning: 102, max_warning: 153, frequency: "daily" },
              { type: "diastolic", min: 50, max: 90, min_warning: 54, max_warning: 86, frequency: "daily" },
              { type: "oxy", min: 90, max: 100, min_warning: 91, max_warning: 99, frequency: "daily" },
              { type: "peak_expiratory_flow", min: "", max: "", min_warning: "", max_warning: "", frequency: "" },
              { type: "blood_glucose_nonfasting", min: 65, max: 280, min_warning: 87, max_warning: 258, frequency: "daily" },
              { type: "temperature", min: 97, max: 100, min_warning: 98, max_warning: 99, frequency: "daily" }
            ],
        };
    },
    computed: {
        ...mapState(["patients"]),
        dtheaders() {
          return [
            { text: "Vital", value: "type" },
            { text: "Reading Frequency", value: "frequency" },
            { text: "Minimum", value: "min" },
            { text: "Maximum", value: "max" },
          ];
        },
        templates: function () {
            return this.patients.templates;
        },
        rpmCarePlanTemplates: function () {
          return this.templates.filter(t => t.category === 'rpm_care_plan');
        },
        ccmCarePlanTemplates: function () {
          return this.templates.filter(t => t.category === 'ccm_care_plan');
        },
        apcmCarePlanTemplates: function () {
          return this.templates.filter(t => t.category === 'apcm_care_plan');
        },
    },
    methods: {
        getProvider(id) {
            return this.patients.providers.find((p) => p.id === id);
        },
        getProviderName(id) {
            const p = this.getProvider(id);
            return p ? p.first_name + " " + p.last_name : "None";
        },
        checkForm(required, formValuesObj) {
            return required
                .filter((key) => !formValuesObj[key])
                .map((key) => key.replace("_", " ") + " is required.");
        },
        editCarePlan(carePlan) {
            if (carePlan.type === "rpm") {
                // Fill in alerts
                let alerts = this.rpmVitalAlerts;
                for (let i = 0; i < alerts.length; i++) {
                  let alert = alerts[i];
                  let matchingAlert = carePlan.alerts.find(cpAlert => cpAlert.type === alert.type);

                  if (matchingAlert) {
                    alerts[i] = matchingAlert;
                  }
                }
                carePlan.alerts = alerts;
            } else {
                carePlan.alerts = [];
            }

            this.editCareplanSelected = carePlan;
            this.editCareplanErrors = {};
            this.editCareplanVisible = true;
        },
        updateCarePlan() {
            const carePlan = this.editCareplanSelected;

            if (carePlan.type === "rpm") {
                if (!this.checkRpmForm()) {
                    this.$nextTick(() => {
                        this.$refs.patientEditCareplanRef.scrollIntoView();
                    });
                    return;
                }
            } else if (carePlan.type == 'ccm') {
                if (!this.checkCcmForm()) {
                    this.$nextTick(() => {
                        this.$refs.patientEditCareplanRef.scrollIntoView();
                    });
                    return;
                }
            } else if (carePlan.type == 'apcm') {
                if (!this.checkApcmForm()) {
                    this.$nextTick(() => {
                        this.$refs.patientEditCareplanRef.scrollIntoView();
                    });
                    return;
                }
            }

            let data = {
                patient_id: carePlan.patient_id,
                id: carePlan.id,
                type: carePlan.type,
                notes: carePlan.notes,
                icd_codes: carePlan.icd_codes,
                health_condition: carePlan.health_condition,
                start_date: carePlan.start_date === '' ? null : carePlan.start_date,
                end_date: carePlan.end_date === '' ? null : carePlan.end_date,
            };

            if (carePlan.type === "rpm") {
                let filteredAlerts = [];
                for (const key in carePlan.alerts) {
                    const alert = carePlan.alerts[key];
                    if (alert.min && alert.max && alert.frequency) {
                        filteredAlerts.push(alert);
                    }
                }

                data["alerts"] = JSON.stringify(filteredAlerts);
            } else if (carePlan.type === "apcm") {
                data["apcm_hcpcs_code"] = carePlan.apcm_hcpcs_code;
            }

            this.loading = true;

            Vue.$http.put(`/api/rpm_ccm_dashboard/care-plan`, data, {params: { token: this.accessToken },})
                .then(() => {
                    this.$awn.success("Care Plan Successfully Edited");
                    this.$emit('get-patient-details');
                })
                .catch(error => this.handleEhrApiErrors(error, this.editCareplanErrors, this.$refs.patientEditCareplanRef)).finally(() => {
                    this.loading = false;
                });
        },
        checkRpmForm() {
            const required = ["HealthCondition", "Notes"];
            this.editCareplanErrors = this.checkForm(required, {
                HealthCondition: this.editCareplanSelected.health_condition,
                Notes: this.editCareplanSelected.notes,
            });

            for (const key in this.editCareplanSelected.alerts) {
                let alert = this.editCareplanSelected.alerts[key];

                if (alert.min || alert.max || alert.frequency) {
                    if (!(alert.min && alert.max && alert.frequency)) {
                        this.editCareplanErrors.push("Alert " + alert.type + " needs to have min, max and frequency filled out.");
                    }

                    const min = parseFloat(alert.min);
                    const max = parseFloat(alert.max);
                    const min_warning = parseFloat(alert.min_warning);
                    const max_warning = parseFloat(alert.max_warning);

                    if (isNaN(min) || isNaN(max)) {
                        this.editCareplanErrors.push("Alert " + alert.type + " min/max need to be valid numbers.");
                    } else if (min >= max) {
                        this.editCareplanErrors.push("Alert " + alert.type + " needs to have a valid min/max range.");
                    }

                  if (isNaN(min_warning) || isNaN(max_warning)) {
                    this.editCareplanErrors.push(
                        "Alert " + alert.type + " min warning/max warning need to be valid numbers."
                    );
                  } else if (min_warning >= max_warning) {
                    this.editCareplanErrors.push(
                        "Alert " + alert.type + " needs to have a valid min_warning/max_warning range."
                    );
                  } else if (min_warning <= min || max_warning >= max) {
                    this.editCareplanErrors.push(
                        "Alert " + alert.type + " needs to have a valid min_warning/max_warning range."
                    );
                  }
                }
            }

            return !this.editCareplanErrors.length;
        },
        checkCcmForm() {
            const required = ["HealthCondition", "Notes"];
            this.editCareplanErrors = this.checkForm(required, {
                HealthCondition: this.editCareplanSelected.health_condition,
                Notes: this.editCareplanSelected.notes,
            });
            return !this.editCareplanErrors.length;
        },
        checkApcmForm() {
            const required = ["HealthCondition", "Notes", 'ApcmHcpcsCode'];
            this.editCareplanErrors = this.checkForm(required, {
                HealthCondition: this.editCareplanSelected.health_condition,
                Notes: this.editCareplanSelected.notes,
                ApcmHcpcsCode: this.editCareplanSelected.apcm_hcpcs_code,
            });
            return !this.editCareplanErrors.length;
        }
    },
};
</script>
