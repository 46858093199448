<template>
  <rpm-layout>
    <loading :loading="loading">
      <div class="grid md:grid-cols-1 lg:grid-cols-4 gap-3">
        <div class="grid col-span-1">
          <div>
            <patient-card
                :patient="patient"
                v-on:get-patient-details="getPatientDetails"
                v-on:show-edit-patient="showEditPatient"
                v-on:show-create-rpm="showCreateRpm"
                v-on:show-create-ccm="showCreateCcm"
                v-on:show-create-apcm="showCreateApcm"
                v-on:show-tos="showTos"
            />
          </div>
        </div>
        <div class="grid md:col-span-1 lg:col-span-3">
          <div>
            <div class="bg-white rounded-lg text-sm font-medium text-gray-500 text-center p-2">
              <div class="grid grid-cols-6">
                <div v-for="tab in tabs" :key="tab" v-on:click="toggleTab(tab)">
                  <div class="grid">
                    <button class="border-b-2 p-2" v-bind:class="{
                        'text-blue-600 border-blue-600 active': active_tab === tab,
                        'border-transparent hover:text-gray-600 hover:border-gray-300 ': active_tab !== tab,
                        }">
                      <v-badge :content="alerts[tab]" :value="alerts[tab]" color="red">
                        {{ tab }}
                      </v-badge>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <vitals v-if="active_tab === 'Vitals'" :patient="patient" :accessToken="accessToken" />
            <care-plans v-if="active_tab === 'Care Plans'" :patient="patient" :accessToken="accessToken" v-on:get-patient-details="getPatientDetails" ref="carePlansComponent" />
            <devices v-if="active_tab === 'Devices'" :patient="patient" :accessToken="accessToken" v-on:get-patient-details="getPatientDetails" ref="devicesComponent" />
            <tasks v-if="active_tab === 'Notes'" :is-notes="true" :patient="patient" ref="notesComponent" v-on:get-patient-details="getPatientDetails" />
            <tasks v-if="active_tab === 'Tasks'" :patient="patient" ref="tasksComponent" v-on:get-patient-details="getPatientDetails" />
            <alerts v-if="active_tab === 'Alerts'" :patient="patient"  v-on:get-patient-details="getPatientDetails" />
          </div>
        </div>
      </div>
    </loading>

    <el-dialog
        :close-on-click-modal="false"
        title="Edit Patient"
        :visible.sync="patientActionsEdit"
        width="50%"
        class="josefin_font">
      <div class="dialog__body">
        <div ref="patientEditRef">
          <RpmDashboardErrors
              v-bind:errors="actionPatientErrors"
              v-bind:title="'Edit Patient Errors'" />
          <Patient
              :patient="patient"
              :statuses="statuses"
              :hide_insurance="true"
              :payers="this.patients.payers"
              :auth-user="this.patients.authUser" />
        </div>
      </div>
      <el-button
          @click="editPatient"
          type="primary"
          :disabled="loading">
        Update patient
      </el-button>
    </el-dialog>

    <el-dialog
        :close-on-click-modal="false"
        title="Create RPM"
        :visible.sync="patientActionsRpmCreate"
        width="50%"
        class="josefin_font">
      <div class="dialog__body">
        <div ref="rpmCreateRef">
          <RpmDashboardErrors
              v-bind:errors="rpmErrors"
              v-bind:title="'Create RPM Errors'" />
          <RpmCarePlan
              v-model="carePlan"
              :templates="rpmCarePlanTemplates"
              :patient="patient" />
        </div>
      </div>
      <el-button
          @click="createRpm"
          type="primary"
          :disabled="loading">
        Create RPM
      </el-button>
    </el-dialog>

    <el-dialog
        :close-on-click-modal="false"
        title="Create CCM"
        :visible.sync="patientActionsCcmCreate"
        width="50%"
        class="josefin_font">
      <div class="dialog__body">
        <div ref="ccmCreateRef">
          <CcmDashboardErrors
              v-bind:errors="ccmErrors"
              v-bind:title="'Create CCM Errors'" />
          <CcmCarePlan
              v-model="carePlan"
              :templates="ccmCarePlanTemplates"
              :patient="patient" />
        </div>
      </div>
      <el-button
          @click="createCcm"
          type="primary"
          :disabled="loading">
        Create CCM
      </el-button>
    </el-dialog>

    <el-dialog
        :close-on-click-modal="false"
        title="Create APCM"
        :visible.sync="patientActionsApcmCreate"
        width="50%"
        class="josefin_font">
      <div class="dialog__body">
        <div ref="apcmCreateRef">
          <CcmDashboardErrors
              v-bind:errors="apcmErrors"
              v-bind:title="'Create APCM Errors'" />
          <ApcmCarePlan
              v-model="carePlan"
              :templates="apcmCarePlanTemplates"
              :patient="patient" />
        </div>
      </div>
      <el-button
          @click="createApcm"
          type="primary"
          :disabled="loading">
        Create APCM
      </el-button>
    </el-dialog>

    <el-dialog
        :close-on-click-modal="false"
        title="TOS"
        :visible.sync="patientActionsTos"
        width="50%"
        class="josefin_font">
      <div class="dialog__body">
        <div ref="agreementRef">
          <RpmDashboardErrors
              v-bind:errors="agreementErrors"
              v-bind:title="'Agreement Errors'" />
          <Agreement
              :patient="patient"
              :legal-form="this.patients.legalForm"
              :auth-user="this.patients.authUser"
              :legal-check="legalCheck" />
        </div>
      </div>
      <el-button
          @click="agreeToLegalAction"
          :disabled="!this.legalChecked || loading"
          type="primary">
        Accept
      </el-button>
    </el-dialog>
  </rpm-layout>
</template>

<script>
import Vue from "vue";
import RpmLayout from "../../../layouts/RpmLayout.vue";
import Loading from "../../../components/Loading.vue";
import Alerts from "./components/Alerts.vue";
import PatientCard from "./components/PatientCard.vue";
import Vitals from "./components/Vitals.vue";
import CarePlans from "./components/CarePlans.vue";
import Devices from "./components/Devices.vue";
import Tasks from "./components/Tasks.vue";
import moment from "moment";
import statuses from '@/helpers/patientStatuses.js';
import {mapState} from "vuex";
import Patient from "@/components/Patient.vue";
import RpmDashboardErrors from "@/components/RpmDashboardErrors.vue";
import CcmDashboardErrors from "@/components/CcmDashboardErrors.vue";
import RpmCarePlan from "@/components/RpmCarePlan.vue";
import CcmCarePlan from "@/components/CcmCarePlan.vue";
import ApcmCarePlan from "@/components/ApcmCarePlan.vue";
import Agreement from "@/components/Agreement.vue";



export default {
  components: {
    RpmLayout,
    Loading,
    PatientCard,
    Vitals,
    CarePlans,
    Devices,
    Tasks,
    Alerts,
    Patient,
    RpmDashboardErrors,
    CcmDashboardErrors,
    RpmCarePlan,
    CcmCarePlan,
    ApcmCarePlan,
    Agreement
  },
  data() {
    return {
      patient: null,
      statuses: statuses,
      loading: true,
      tabs: ["Vitals", "Care Plans", "Devices", "Notes", "Tasks", "Alerts"],
      active_tab: "Vitals",
      accessToken: null,
      patientActionsEdit: false,
      actionPatientErrors: [],
      rpmErrors: [],
      ccmErrors: [],
      apcmErrors: [],
      agreementErrors: [],
      patientActionsCcmCreate: false,
      patientActionsRpmCreate: false,
      patientActionsApcmCreate: false,
      patientActionsTos: false,
      legalCheck: {
        firstContact: false,
        cellService: false,
        dailyCommitment: false,
        reviewTos: false
      },
      carePlan: {}
    };
  },
  computed: {
    ...mapState(["patients"]),
    templates: function () {
      return this.patients.templates;
    },
    rpmCarePlanTemplates: function () {
      return this.templates.filter(t => t.category === 'rpm_care_plan');
    },
    ccmCarePlanTemplates: function () {
      return this.templates.filter(t => t.category === 'ccm_care_plan');
    },
    apcmCarePlanTemplates: function () {
      return this.templates.filter(t => t.category === 'apcm_care_plan');
    },
    alerts() {
      const today = moment().startOf('day');

      return {
        Vitals: 0,
        "Care Plans": 0,
        Devices: 0,
        "Notes": this.patient && this.patient.tasks ? this.patient.tasks.filter(t => t.status !== 'closed' && t.status !== 'open').length : 0,
        "Tasks": this.patient && this.patient.tasks ? this.patient.tasks.filter(t => {
          return t.status === 'open';
        }).length : 0,
        Alerts: this.patient && this.patient.vital_alerts_unresolved ? this.patient.vital_alerts_unresolved.length + (this.patient.vital_warnings_unresolved ? this.patient.vital_warnings_unresolved.length : 0) : 0,
      }
    },
    legalChecked() {
      return this.legalCheck.firstContact &&
          this.legalCheck.cellService &&
          this.legalCheck.dailyCommitment &&
          this.legalCheck.reviewTos;
    },
  },
  methods: {
    async getAccessToken() {
      this.accessToken = await this.$auth.getTokenSilently();
    },
    getPatientDetails() {
      return Vue.$http
        .get("/api/rpm_ccm_dashboard/patient_details", {
          params: {
            patient_id: this.$route.params.id,
            token: this.accessToken,
          },
        })
        .then((res) => (this.patient = res.data.data))
        .catch((error) => this.handleEhrApiErrors(error, this.errors))
        .finally(() => {
          this.loading = false;
        });
    },
    toggleTab(tab) {
      this.active_tab = tab;
    },
    showEditPatient(patient) {
      this.actionPatientErrors = [];
      this.resetNewPatient();
      this.showActionPatientModal(patient);
    },
    showCreateRpm(patient) {
      this.rpmErrors = [];
      this.resetCarePlan();
      this.showActionRpmModal(patient);
    },
    showCreateCcm(patient) {
      this.ccmErrors = [];
      this.resetCarePlan();
      this.showActionCcmModal(patient);
    },
    showCreateApcm(patient) {
      this.apcmErrors = [];
      this.resetCarePlan();
      this.showActionApcmModal(patient);
    },
    showTos(patient) {
      this.agreementErrors = [];

      if (patient.agreed_to_client_form) {
        this.legalCheck.reviewTos = true;
        this.legalCheck.firstContact = true;
        this.legalCheck.cellService = true;
        this.legalCheck.dailyCommitment = true;
      } else {
        this.resetLegalCheck();
      }

      this.showActionTosModal(patient);
    },
    showActionPatientModal(selectedPatient) {
      this.patientActionSelected = selectedPatient;
      this.patientActionsEdit = true;
    },
    showActionRpmModal(selectedPatient) {
      this.patientActionSelected = selectedPatient;
      this.patientActionsRpmCreate = true;
    },
    showActionCcmModal(selectedPatient) {
      this.patientActionSelected = selectedPatient;
      this.patientActionsCcmCreate = true;
    },
    showActionApcmModal(selectedPatient) {
      this.patientActionSelected = selectedPatient;
      this.patientActionsApcmCreate = true;
    },
    showActionTosModal(selectedPatient) {
      this.patientActionSelected = selectedPatient;
      this.patientActionsTos = true;
    },
    checkPatientEditForm() {
      const required = ["state", "first_name", "status"];
      this.actionPatientErrors = this.checkForm(required, this.patient);
      return !this.actionPatientErrors.length;
    },
    checkForm(required, formValuesObj) {
      let errors = [];

      for (const key in required) {
        if (!formValuesObj.hasOwnProperty(required[key])) {
          errors.push(required[key].replace("_", " ") + " is required.");
        } else if (
            formValuesObj.hasOwnProperty(required[key]) &&
            required.indexOf(required[key]) !== -1 &&
            !formValuesObj[required[key]]
        ) {
          errors.push(required[key].replace("_", " ") + " is required.");
        }
      }

      return errors;
    },
    async editPatient() {
      if (!this.checkPatientEditForm()) {
        this.$refs.patientEditRef.scrollIntoView();
        return;
      }

      this.loading = true;

      try {
        const response = await Vue.$http.put(
            `/api/rpm_ccm_dashboard/update_patient`,
            this.patient,
            { params: { token: await this.$auth.getTokenSilently() }}
        );

        //await this.$store.dispatch('getAuthUser');
        //this.patient = response.data.data;
        await this.getPatientDetails();
        this.$awn.success("Patient Successfully Edited");
        this.patientActionsEdit = false;

      } catch (error) {
        this.handleEhrApiErrors(error, this.actionPatientErrors, this.$refs.patientEditRef);
      } finally {
        this.loading = false;
      }
    },
    checkRpmForm() {
      const required = ['HealthCondition', 'Notes'];
      this.rpmErrors = this.checkForm(required, { HealthCondition: this.carePlan.health_condition, Notes: this.carePlan.notes });

      for (const key in this.carePlan.alerts) {
        let alert = this.carePlan.alerts[key];

        if (alert.min || alert.max || alert.frequency) {
          if (!(alert.min && alert.max && alert.frequency)) {
            this.rpmErrors.push(
                "Alert " +
                key +
                " needs to have min, max and frequency filled out."
            );
          }

          const min = parseFloat(alert.min);
          const max = parseFloat(alert.max);
          const min_warning = parseFloat(alert.min_warning);
          const max_warning = parseFloat(alert.max_warning);

          if (isNaN(min) || isNaN(max)) {
            this.rpmErrors.push(
                "Alert " + alert.type + " min/max need to be valid numbers."
            );
          } else if (min >= max) {
            this.rpmErrors.push(
                "Alert " + alert.type + " needs to have a valid min/max range."
            );
          }

          if (isNaN(min_warning) || isNaN(max_warning)) {
            this.rpmErrors.push(
                "Alert " + alert.type + " min warning/max warning need to be valid numbers."
            );
          } else if (min_warning >= max_warning) {
            this.rpmErrors.push(
                "Alert " + alert.type + " needs to have a valid min_warning/max_warning range."
            );
          } else if (min_warning <= min || max_warning >= max) {
            this.rpmErrors.push(
                "Alert " + alert.type + " needs to have a valid min_warning/max_warning range."
            );
          }
        }
      }

      return !this.rpmErrors.length;
    },
    checkCcmForm() {
      const required = ['HealthCondition', 'Notes'];
      this.ccmErrors = this.checkForm(required, { HealthCondition: this.carePlan.health_condition, Notes: this.carePlan.notes });
      return !this.ccmErrors.length;
    },
    async createRpm() {
      if (!this.checkRpmForm()) {
        this.$nextTick(() => {
          this.$refs.rpmCreateRef.scrollIntoView();
        });

        return;
      }

      this.loading = true;

      let filteredAlerts = [];
      for (const key in this.carePlan.alerts) {
        const alert = this.carePlan.alerts[key];
        if (alert.min && alert.max && alert.frequency) {
          filteredAlerts.push(alert);
        }
      }

      const response = await Vue.$http.post(`/api/rpm_ccm_dashboard/care-plan`, { patient_id: this.patientActionSelected.id, alerts: JSON.stringify(filteredAlerts), type: 'rpm', notes: this.carePlan.notes, health_condition: this.carePlan.health_condition, start_date: this.carePlan.start_date, end_date: this.carePlan.end_date, icd_codes: this.carePlan.icd_codes }, { params: { token: this.accessToken } }
      ).then(async () => {
        this.$awn.success("Rpm Care Plan Created");
        await this.getPatientDetails();
        this.patientActionsRpmCreate = false;
      })
          .catch((error) =>
              this.handleEhrApiErrors(
                  error,
                  this.rpmErrors,
                  this.$refs.rpmCreateRef
              )
          ).finally(() => {
        this.loading = false;
      });
    },
    checkApcmForm() {
      const required = ['HealthCondition', 'Notes', 'ApcmHcpcsCode'];
      this.apcmErrors = this.checkForm(required, { HealthCondition: this.carePlan.health_condition, Notes: this.carePlan.notes, ApcmHcpcsCode: this.carePlan.apcm_hcpcs_code });
      return !this.apcmErrors.length;
    },
    async createCcm() {
      if (!this.checkCcmForm()) {
        this.$nextTick(() => {
          this.$refs.ccmCreateRef.scrollIntoView();
        });
        return;
      }

      this.loading = true;

      const response = await Vue.$http.post(`/api/rpm_ccm_dashboard/care-plan`, { patient_id: this.patientActionSelected.id, type: 'ccm', notes: this.carePlan.notes, health_condition: this.carePlan.health_condition, start_date: this.carePlan.start_date, end_date: this.carePlan.end_date, icd_codes: this.carePlan.icd_codes }, { params: { token: this.accessToken } }
      ).then(async () => {
        await this.getPatientDetails();
        this.patientActionsCcmCreate = false;
        this.$awn.success("CCM Care Plan Created");
      })
          .catch((error) =>
              this.handleEhrApiErrors(
                  error,
                  this.ccmErrors,
                  this.$refs.ccmCreateRef
              )
          ).finally(() => {
        this.loading = false;
      });
    },
    async createApcm() {
      if (!this.checkApcmForm()) {
        this.$nextTick(() => {
          this.$refs.apcmCreateRef.scrollIntoView();
        });
        return;
      }

      this.loading = true;

      const response = await Vue.$http.post(`/api/rpm_ccm_dashboard/care-plan`, { patient_id: this.patientActionSelected.id, type: 'apcm', notes: this.carePlan.notes, health_condition: this.carePlan.health_condition, start_date: this.carePlan.start_date, end_date: this.carePlan.end_date, icd_codes: this.carePlan.icd_codes, apcm_hcpcs_code: this.carePlan.apcm_hcpcs_code }, { params: { token: this.accessToken } }
      ).then(async () => {
        this.$awn.success("APCM Care Plan Created");
        await this.getPatientDetails();
        this.patientActionsApcmCreate = false;
      })
          .catch((error) =>
              this.handleEhrApiErrors(
                  error,
                  this.apcmErrors,
                  this.$refs.apcmCreateRef
              )
          ).finally(async () => {
        this.loading = false;

      });
    },
    async agreeToLegalAction() {
      this.loading = true;

      Vue.$http
          .post(
              `/api/rpm_ccm_dashboard/agree_to_legal`,
              {},
              {
                params: {
                  patient_id: this.patientActionSelected.id,
                  token: this.accessToken
                },
              }
          )
          .then(async () => {
            await this.getPatientDetails();
            this.patientActionsTos = false;
            this.$awn.success("Patient Successfully Agreed to Legal");
          })
          .catch((error) =>
              this.handleEhrApiErrors(
                  error,
                  this.agreementErrors,
                  this.$refs.agreementRef
              )
          ).finally(() => {
        this.loading = false;
      });
    },
    resetNewPatient() {
      this.currentPanel = "register";
      this.newPatient = {
        primary_client_id: null,
        status: 'onboard',
        first_name: "",
        middle_name: "",
        last_name: "",
        email: "",
        dob: "",
        sex: "",
        timezone: "",
        phone: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        country_id: "1",
        member_id: "",
        member_group_id: "",
        payer_id: "",
        emergency_contact: {
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          address1: "",
          address2: "",
          city: "",
          state: "",
          zip: "",
          country_id: "1",
        }
      };
      this.createdPatient = {};
      this.legalCheck = {
        firstContact: false,
        cellService: false,
        dailyCommitment: false,
        reviewTos: false,
      };
      this.eligibilityStepModalResponse = null;
      this.resetCarePlan();
    },
    resetLegalCheck() {
      this.legalCheck = {
        firstContact: false,
        cellService: false,
        dailyCommitment: false,
        reviewTos: false,
      };
    },
    resetCarePlan() {
      this.rpmErrors = [];
      this.ccmErrors = [];
      this.apcmErrors = [];

      this.carePlan = {};
      this.carePlan.icd_codes = [];
      this.carePlan.alerts = [
        { type: "weight", min: -5, max: 5, min_warning: -4, max_warning: 4, frequency: "daily" },
        { type: "pulse", min: 50, max: 100, min_warning: 55, max_warning: 95, frequency: "daily" },
        { type: "systolic", min: 95, max: 160, min_warning: 102, max_warning: 153, frequency: "daily" },
        { type: "diastolic", min: 50, max: 90, min_warning: 54, max_warning: 86, frequency: "daily" },
        { type: "oxy", min: 90, max: 100, min_warning: 91, max_warning: 99, frequency: "daily" },
        { type: "peak_expiratory_flow", min: "", max: "", min_warning: "", max_warning: "", frequency: "" },
        { type: "blood_glucose_nonfasting", min: 65, max: 280, min_warning: 87, max_warning: 258, frequency: "daily" },
        { type: "temperature", min: 97, max: 100, min_warning: 98, max_warning: 99, frequency: "daily" }
      ]
    },
  },
  created: async function () {
    await this.getAccessToken();
    Promise.all([
      this.getPatientDetails(),
      !this.$store.state.patients.authUser.id ? this.$store.dispatch('getAuthUser') : null,
      !this.$store.state.patients.providers.length ? this.$store.dispatch('getProviders') : null,
      !this.$store.state.patients.templates.length ? this.$store.dispatch('getTemplates') : null,
      !this.$store.state.patients.payers.length ? this.$store.dispatch('getPayers') : null,
      !this.$store.state.patients.legalForm.text ? this.$store.dispatch('getLegalForm') : null,
    ]).finally(() => {
      this.loading = false;
    });
  },
  watch: {
    $route: "getPatientDetails",
  },
};
</script>

<style scoped>
.active_tab {
  color: #5e60ce;
  border-color: #5e60ce;
}

.inactive_tab:hover {
  border-color: transparent;
}
</style>
